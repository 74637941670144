<template>
  <div>
    <component
      :is="containerOrDiv(element)"
      v-for="element in nestedElements"
      :key="element.id"
    >
      <component
        :is="element.name"
        :element="element"
        data-column="true"
        :data-column-layout="layout"
        :data-column-placement="columnNumber"
      />
    </component>
  </div>
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"

  const ELEMENTS_NEEDING_CONTAINER = [
    "button",
    "wax_intro_link",
    "wax_intro_product",
  ]

  export default {
    components: {
      Container: defineAsyncComponent(
        () => import("~/components/Container/index.vue"),
      ),
      articles_list: defineAsyncComponent(() => import("./articles_list")),
      announcement: defineAsyncComponent(() => import("./announcement")),
      product_feature: defineAsyncComponent(() => import("./product_feature")),
      article_teaser_new_storefront: defineAsyncComponent(
        () => import("./article_teaser_new_storefront"),
      ),
      product: defineAsyncComponent(() => import("./product")),
      text_block: defineAsyncComponent(() => import("./text_block")),
      block_quote: defineAsyncComponent(() => import("./block_quote")),
      alchemyButton: defineAsyncComponent(() => import("./button")),
      headline: defineAsyncComponent(() => import("./headline")),
      illustration: defineAsyncComponent(() => import("./illustration")),
      image_full_width: defineAsyncComponent(
        () => import("./image_full_width"),
      ),
      wistia_embed: defineAsyncComponent(() => import("./wistia_embed")),
      product_list: defineAsyncComponent(() => import("./product_list")),
      product_slider: defineAsyncComponent(() => import("./product_slider")),
      add_to_cart_list: defineAsyncComponent(
        () => import("~/alchemy/elements/project_items"),
      ),
      top_selling_fragrance_oils: defineAsyncComponent(
        () => import("./top_selling_fragrance_oils"),
      ),
      taxon_slider: defineAsyncComponent(() => import("./taxon_slider")),
      testimonial: defineAsyncComponent(() => import("./testimonial")),
      gallery: defineAsyncComponent(() => import("./gallery")),
      document_link: defineAsyncComponent(() => import("./document_link")),
      cards: defineAsyncComponent(() => import("./cards")),
      wax_intro_link: defineAsyncComponent(() => import("./wax_intro_link")),
      wax_intro_product: defineAsyncComponent(
        () => import("./wax_intro_product"),
      ),
      collection_oneozsale: defineAsyncComponent(() => import("./collection_oneozsale")),
    },
    mixins: [AlchemyElement],
    props: {
      columnNumber: {
        type: Number,
        default: 1,
      },
      layout: {
        type: String,
        default: "",
      },
    },
    computed: {
      nestedElements() {
        const renameButtonElement = (element) => {
          if (element.name === "button") {
            element.name = "alchemyButton"
          }
          return element
        }
        return renameButtonElement(this.element).nestedElements
      },
    },
    methods: {
      containerOrDiv(element) {
        if (ELEMENTS_NEEDING_CONTAINER.includes(element.name)) {
          return "Container"
        }
        return "div"
      },
    },
  }
</script>
